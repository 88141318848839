import axios from 'axios';

const HostName = process.env.NODE_ENV === "development" ? 'http://kqstudios.com/' :
  `http://kqstudios.com/`;
  
export function inViewport( element ){
  // console.log(element);
  
  // Get the elements position relative to the viewport

  const bb = element.getBoundingClientRect();
  
  // Check if the element is outside the viewport
  // Then invert the returned value because you want to know the opposite

  return !(bb.top > window.innerHeight || bb.bottom < 0);
}

export function isInViewport(element,offset = 0) {
  if (!element) return false;
  
  const top = element.getBoundingClientRect().top;
  return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
}

var parallaxContainer = document.getElementsByClassName("react-parallax");
var menu = document.getElementsByClassName("ant-menu-item");
var bmMenu = document.getElementsByClassName("bm-item");
var leadspace = window.innerWidth > 991 ? 350 : 100;

//add highlight function
function addHighlight(i) {
  if(window.innerWidth > 991) {
    menu[i].classList.add("ant-menu-item-selected");
  } else {
    bmMenu[i].classList.add("kq-menu-selected");
  }
}

//remove highlight function
function removeHighlight(i) {
  if(window.innerWidth > 991) {
    menu[i].classList.remove("ant-menu-item-selected");
  } else if(document.getElementsByClassName("kq-menu-selected")) {
    bmMenu[i].classList.remove("kq-menu-selected");
  }
}

// scroll progress bar
function scrollIndicator(scroll, height) {
  var scrolled = (scroll / height) * 100;
  document.getElementById("myBar").style.width = scrolled + "%";
}

// navigate scroll viewpoint
window.onscroll = function(event) {
  var scrollTop = event.target.scrollingElement.scrollTop;
	var scrollHeight = event.target.scrollingElement.scrollHeight;
	var height = event.target.scrollingElement.clientHeight;
  scrollIndicator(scrollTop, (scrollHeight - height));
  if(scrollTop >= parallaxContainer[0].offsetTop) {
		addHighlight(0);
		removeHighlight(1);
		removeHighlight(2);
		removeHighlight(3);
		removeHighlight(4);
    removeHighlight(5);
	}
	if(scrollTop >= parallaxContainer[1].offsetTop-leadspace) {
		addHighlight(1);
		removeHighlight(0);
		removeHighlight(2);
		removeHighlight(3);
		removeHighlight(4);
    removeHighlight(5);
	}
	if(scrollTop >= parallaxContainer[2].offsetTop-leadspace) {
		addHighlight(2);
		removeHighlight(0);
		removeHighlight(1);
		removeHighlight(3);
		removeHighlight(4);
    removeHighlight(5);
	}
	if(scrollTop >= parallaxContainer[3].offsetTop-leadspace) {
		addHighlight(3);
		removeHighlight(0);
		removeHighlight(1);
		removeHighlight(2);
		removeHighlight(4);
    removeHighlight(5);
	}
	if(scrollTop >= parallaxContainer[4].offsetTop-leadspace && scrollTop >= parallaxContainer[5].offsetTop-leadspace) {
		addHighlight(4);
		removeHighlight(0);
		removeHighlight(1);
		removeHighlight(2);
		removeHighlight(3);
    removeHighlight(5);
	}
  if(scrollTop >= parallaxContainer[6].offsetTop-leadspace) {
		addHighlight(5);
		removeHighlight(0);
		removeHighlight(1);
		removeHighlight(2);
		removeHighlight(3);
    removeHighlight(4);
  }
};





export function sendMail(sendTo,subject,message,callBack){
  axios.post(`${HostName}/api/sendmail.php`,
  {
    sendTo,
    subject,
    message
  }).then(response => {
    callBack(response.data);
  })
}

export function landingPage(callBack){
axios({method:'GET',url:`${HostName}/api/landing_page.php`})
.then((response)=>{
callBack(response.data);

})
}

export function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}