import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Row, Col, Card, Icon, Button } from "antd";

import Drawerjob from "../components/drawer_job";

import { inViewport } from "../helpers/helper";

export default class Awesomejobs extends Component {
  constructor(props) {
    super(props);
    this.isAnimated = false;
    this.state={
        showJob:false,
        selectedItem:'Concept artist'
    }
  }
  render() {
    const {
      AppData: {
        textContent: { jobs:{items,descriptions} },
        mediaContent:{common:{bg_white}}
      }
    } = this.props;
    const {showJob,selectedItem} = this.state;
    return (
      <Parallax
        bgImage={bg_white}
        strength={1000}
        className={"kq-studios-job"}
        renderLayer={percentage => {
          
          if (
            this.viewElement &&
            inViewport(this.viewElement) &&
            !this.isAnimated
          )
            this.isAnimated = true;
          return (
            <div
              className={`kq-job-content ${
                this.isAnimated ? "kq-studios-job-view" : ""
              }`}
              ref={ele => (this.viewElement = ele)}
              id="careers"
            >
            <div className="kq-jobs-heading">CAREERS</div>
              <div>
                We constantly look for best talent in the industry. Do you have
                skills and experience to fulfill the responsibilities in the
                following roles?
              </div>
              <Row type="flex" className={'kq-jobs-holder'}>
                {items.map((item,index) => (
                  <Col xl={{span:11}} xs={{span:24}} className={"kq-jobs-text"} key={index}>
                    <Card>
                      <Icon type="shopping" />
                      <span className={"kq-job-name"}>{item}</span>
                      <Button size="small" onClick={(event)=>{
                        event.preventDefault();
                          this.setState({showJob:true,selectedItem:item})
                      }}>
                        View
                      </Button>
                    </Card>
                  </Col>
                ))}
              </Row>
             <Drawerjob 
             onClose={(event)=>{
              event.preventDefault();
               this.setState({showJob:false})}
              } 
             content={descriptions[selectedItem]} 
             selectedItem={selectedItem}
             showJob={showJob}/>
             <div className={"kq-mail-portfolio"}>
             <div>Send Your resumes to </div>
             <div><a href="mailto:hr@knowledgeq.com">hr@knowledgeq.com</a> <span>/</span> <a href="mailto:careers@knowledgeq.com">careers@knowledgeq.com</a></div>
             </div>
            </div>
          );
        }}
      />
    );
  }
}
