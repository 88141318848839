import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { List } from "antd";

import Carouselslider from "../components/carousel_slider";

import { inViewport } from "../helpers/helper";

export default class Whyus extends Component {
  constructor(props) {
    super(props);
    this.isAnimated = false;
  }
  render() {
    const {
      AppData: {
        mediaContent: {
          why_us: { images },
          common:{black_bg}
        },
        textContent: { why_us }
      }
    } = this.props;
    return (
      <Parallax
        bgImage={black_bg}
        strength={1000}
        className={"kq-studios-whyus new-whyus"}
        renderLayer={percentage => {
          if (
            this.viewElement &&
            inViewport(this.viewElement) &&
            !this.isAnimated
          )
            this.isAnimated = true;

          return (
            <div
              className={`kq-whyus-content ${
                this.isAnimated ? "kq-studios-whyus-view" : ""
              }`}
              ref={ele => (this.viewElement = ele)}
              id="why_us"
            >
              <div className={"kq-whyus-content-for-new"}>
              <Carouselslider srcData={[...images]} disableClickable={true} speed={2000} autoplaySpeed={3500}/>
              <div className={"kq-whyus-list-title"}>
              <div className={"kq-title-holder-whyus"}><span>Why Us</span></div>
              <List
                    size={"large"}
                    dataSource={why_us}
                    renderItem={item => <List.Item className={"kq-whyus-list"}>{item}</List.Item>}
                  />
                  </div>
                  </div>
            </div>
          );
        }}
      />
    );
  }
}
