import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Carousel, Button } from "antd";

import Videoplayer from "../components/video_player";

// import { inViewport } from "../helpers/helper";

export default class Ourworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "video",
      src: null,
      srcIndex: 0,
      isAutoPlay:false
    };
  }

  videoPlayerPrevious = () => {
    const videos = this.props.AppData.mediaContent.our_works.videos;
    const videosLength =videos.length - 1;
    let finalIndex = this.state.srcIndex - 1;
    if (finalIndex < 0) {
      finalIndex = videosLength;
    }
    this.setState({ srcIndex: finalIndex,isAutoPlay:true });
  };

  videoPlayernext = () => {
    const videos = this.props.AppData.mediaContent.our_works.videos;
    const videosLength =videos.length - 1;
    const { srcIndex } = this.state;
    let finalIndex = srcIndex + 1;
    if (videosLength < finalIndex) {
      finalIndex = 0;
    }
    this.setState({ srcIndex: finalIndex ,isAutoPlay:true,src:videos[srcIndex].videoLink});
  };
  render() {
    const {
      AppData: {
        mediaContent: {
          our_works: { videos, bg }
        }
      }
    } = this.props;
    const { srcIndex,isAutoPlay } = this.state;

    return (
      <Parallax
        bgImage={bg}
        strength={800}
        className={"kq-studios-ourworks"}
      >
        <div
          className={`kq-ourworks-content`}
        >
          <div className={"kq-ourworks-title"}>
            Some of our recent works
              </div>
          <div className={"kq-video-holder"}>
            <Button
              icon={"none"}
              size={"small"}
              className={"kq-prev-btn"}
              onClick={this.videoPlayerPrevious}
            />
            <Videoplayer src={videos[srcIndex].videoLink} autoPlay={isAutoPlay} />
            <Button
              icon={"none"}
              size={"small"}
              className={"kq-next-btn"}
              onClick={this.videoPlayernext}
            />
          </div>
          <div className={"kq-ourworks-video-list"}>
            <Carousel
              autoplay={false}
              slidesToShow={4}
              slidesToScroll={1}
              arrows={true}
              swipeToSlide={true}
            >
              {videos.map((item, index) => (
                <img
                  key={index}
                  src={item.posterLink}
                  className={`${
                    index===srcIndex ? "selected-img" : ""
                    }`}
                  alt={item}
                  onClick={() => {
                    this.setState({ src: item.videoLink, srcIndex:index,isAutoPlay:true });
                  }}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </Parallax>
    );
  }
}
