import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Row, Col,Divider,Icon } from "antd";

import Leavemessage from "../components/leave_message"
import { landingPage } from "../helpers/helper";



export default class Contactus extends Component {
  state={
    views:0
  }
  componentDidMount(){
    landingPage((views)=>this.setState({views}));
  }
  render() {
    const {
      AppData: {
        textContent: { contact_us },
        mediaContent:{contact_us:{socialMedia,bg}}
      }
    } = this.props;
    return (
      <Parallax bgImage={bg} strength={10} className={"kq-studios-contactus"}>
        <Row type="flex" justify="space-around"  id="get_in_touch" className={'kq-contactus-content'}>
          <Col xl={{span:10}} xs={{span:24}} className={"kq-contactus-reachus"}>
          <div className="kq-contactus-heading">Reach Us</div>
            {contact_us.map((item,index) => (
              <div dangerouslySetInnerHTML={{ __html: item }} key={index}/>
            ))}
            <div className={'kq-social-links'}>
            {
              socialMedia.map((item,index)=><span key={index}> <a href={item.link} target="_blank"><img src={item.imageSrc} alt="" /></a></span>)
            }
            </div>
          </Col>
          <Col  xl={{span:6,offset:1}} xs={{span:24}} className={"kq-contactus-location"}>
          <div className="kq-contactus-heading">Location</div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d3370.502063173954!2d76.976842!3d10.931427999999999!3m2!1i1024!2i768!4f13.1!2m1!1sknowledgeq+rathinam!5e1!3m2!1sen!2sin!4v1417180889612"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: "0", height: "auto" }}
              title={"KQ location"}
            />
          </Col>
          <Col xl={{span:6,offset:1}} xs={{span:24}} className={"kq-contactus-leavemsg"}>
            <Leavemessage />
          </Col>
        </Row>
        <Divider className={'kq-contactus-divider'}/>
        <Row type="flex" justify="space-around">
        <Col span={24} className={"kq-copyrights"}>
        <Icon type="copyright" /> 2019 All Rights Reserved 1.0.3 
        </Col>
        <Col span={24} className={"kq-logo"}>
        </Col>
        
        <div className={'kq-views-count'}>Visitors : {this.state.views}</div>
        </Row>
      </Parallax>
    );
  }
}
