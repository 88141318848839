import React, { Component } from "react";
import { Input, Icon, Button, notification, Spin } from "antd";
import Recaptcha from "react-recaptcha";

import { sendMail } from "../helpers/helper";

const { TextArea } = Input;

export default class Leavemessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      email: "",
      subject: "",
      message: "",
      captchaVerified: false,
      loading: false
    };
    notification.config({
      placement: "bottomLeft",
      duration: 0
    });
  }

  emitEmpty = type => {
    this[type].focus();
    this.setState({ [type]: "" });
  };

  onChangeValue = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  onLoadedCaptcha = () => {
    console.log("captcha loaded sucessfully");
  };

  verifyCallback = value => {
    if (value) this.setState({ captchaVerified: true });
  };

  afterMessageSent = response => {
    if (response) {
      this.setState({
        loading: false,
        userName: "",
        email: "",
        subject: "",
        message: "",
        captchaVerified: false
      });
      this.showNotification(
        "kq-notification-sucess",
        "success",
        "Your message sent!!"
      );
    } else {
      this.setState({ loading: false });
      this.showNotification(
        "kq-notification-failure",
        "Failed",
        "your message not sent!!"
      );
    }
  };
  showNotification = (className, message, description) => {
    notification.destroy();
    notification.open({
      className,
      message,
      description,
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />
    });
  };
  submitLeaveMessage = e => {
    e.preventDefault();
    notification.destroy();
    const { captchaVerified, userName, email, subject, message } = this.state;
    if (captchaVerified) {
      this.setState({ loading: true });
      const customizeMessage = `<div>The username is <b>${userName}</b></div>
      <div>Email id is <b>${email}</b></div><div>They are trying to convey the message <b>${message}</b></div>`;
      sendMail(
        "hr@knowledgeq.com",
        subject,
        customizeMessage,
        this.afterMessageSent
      );
    } else {
      this.showNotification(
        "kq-notification",
        "Not Allowed",
        "Please verify your a human!!"
      );
    }
  };

  render() {
    const { userName, email, subject, message } = this.state;
    return (
      <Spin spinning={this.state.loading} delay={500}>
        <form name={"leavemessage"} onSubmit={this.submitLeaveMessage}>
        <div className="kq-contactus-heading">Leave a Message</div>
          <Input
            className={"kq-input-box"}
            placeholder="Enter your username"
            required
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={
              userName && (
                <Icon
                  type="close-circle"
                  onClick={() => {
                    this.emitEmpty("userName");
                  }}
                />
              )
            }
            value={userName}
            onChange={e => {
              this.onChangeValue(e, "userName");
            }}
            ref={node => (this.userName = node)}
          />
          <Input
            className={"kq-input-box"}
            placeholder="Enter your email"
            required
            prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={
              email && (
                <Icon
                  type="close-circle"
                  onClick={() => {
                    this.emitEmpty("email");
                  }}
                />
              )
            }
            value={email}
            onChange={e => {
              this.onChangeValue(e, "email");
            }}
            ref={node => (this.email = node)}
          />
          <Input
            className={"kq-input-box"}
            placeholder="Subject"
            required
            prefix={<Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />}
            suffix={
              subject && (
                <Icon
                  type="close-circle"
                  onClick={() => {
                    this.emitEmpty("subject");
                  }}
                />
              )
            }
            value={subject}
            onChange={e => {
              this.onChangeValue(e, "subject");
            }}
            ref={node => (this.subject = node)}
          />
          <TextArea
            className={"kq-input-box"}
            placeholder="Message"
            required
            value={message}
            onChange={e => {
              this.onChangeValue(e, "message");
            }}
            ref={node => (this.message = node)}
          />
          <div className={"kq-contact-us-submit"}>
            <Recaptcha
              sitekey={"6Le7H5EUAAAAADi59G7civhPAF-sYgPZkpHLh3ay"}
              theme="light"
              render="explicit"
              onloadCallback={this.onLoadedCaptcha}
              verifyCallback={this.verifyCallback}
            />
            <Button className={"kq-btn"} htmlType="submit" value="Submit">
              Submit
            </Button>
          </div>
        </form>
      </Spin>
    );
  }
}
