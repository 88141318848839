import React from 'react'
import { Drawer,Form,Input,Icon,Button,Spin,notification } from 'antd';
import { sendMail } from "../helpers/helper";

export  class Drawerjob extends React.Component {
  constructor(props){
    super(props);
    this.state = { 
      visible: true,
      loading:false };
    notification.config({
      placement: "bottomLeft",
      duration: 0
    });
  }
  


  onClose = () => {
    this.setState({
      visible: false
    });
    this.props.onClose()
  };
  handleSubmit=(event)=>{
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {Name,email,phonenumber,portfoliolink} = values
        this.setState({loading:true});
        const jobdetails = `
        <div>Applied post <b>${this.props.selectedItem}</b></div>
        <div>The Candidate Name is <b>${Name}</b></div>
        <div>Email id is <b>${email}</b></div>
        <div>Phone number is <b>${phonenumber}</b></div>
        <div>Portfolio link is <b>${portfoliolink}</b></div>`;
        sendMail(
          "careers@knowledgeq.com",
          `Applied for ${this.props.selectedItem}`,
          jobdetails,
          this.afterMessageSent
        );
      }
    });

  }
  afterMessageSent = ()=>{
    this.setState({loading:false});
    notification.destroy();
    notification.open({
      className:'job-applied-notfication',
      message:'Applied',
      description:'Your Job application sent',
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />
    });
  }
  render() {
    const {onClose,content,selectedItem,showJob,form:{getFieldDecorator} } = this.props;
    return (
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={showJob}
          width={window.innerWidth>1100?500:window.innerWidth/1.5}
        >
        <h2>{selectedItem}</h2>
          <div dangerouslySetInnerHTML={{__html:content}}></div>
          <Spin spinning={this.state.loading} delay={500}>
          <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('Name', {
            rules: [{ required: true, message: 'Please input your name!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" className={'field-required'} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Enter your email" className={'field-required'}/>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phonenumber', {
            rules: [{ 
              required: true, 
              message: 'Please input your phonenumber!' 
            }]
          })(
            <Input prefix={<Icon type="contacts" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="Enter your phonenumber" className={'field-required'}/>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('portfoliolink', {
            rules: [{ 
              required: true, message: 'Please input your portfolio link!',
            // pattern:new RegExp("(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)") 
          }],
          })(
            <Input prefix={<Icon type="link" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Enter your portfolio link" className={'field-required'}/>
          )}
        </Form.Item>
        <Form.Item>
         
          <Button type="primary" htmlType="submit" className="login-form-button">
            Apply
          </Button>
        </Form.Item>
      </Form>
      </Spin>
        </Drawer>
    );
  }
}
export default  Form.create({ name: 'job_apply' })(Drawerjob);