import React, { Component } from "react";
import { Layout, } from "antd";

import Home from "./container/home";
import Aboutus from "./container/about_us";
import Whyus from "./container/why_us";
import Whatwedo from "./container/what_we_do";
import Ourworks from "./container/our_works";
import Awesomejobs from "./container/awesome_jobs";
import Contactus from "./container/contact_us";


class App extends Component {

  componentDidMount() {
    document.addEventListener('contextmenu', this.stopRightClick);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.stopRightClick)
  }

  stopRightClick = (event) => {
    event.preventDefault();
    return false;
  }

  render() {
    const { AppData } = this.props;
    return (
      <Layout >
        <Home AppData={AppData} />
        <Aboutus AppData={AppData} />
        <Whyus AppData={AppData} />
        <Whatwedo AppData={AppData} />
        <Ourworks AppData={AppData} />
        <Awesomejobs AppData={AppData} />
        <Contactus AppData={AppData} />
      </Layout>
    );
  }
}

export default App;
