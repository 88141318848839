import React, { Component, Fragment } from "react";
import { Parallax } from "react-parallax";
import { Layout, Menu, BackTop } from "antd";
import { bubble as Menuburger } from "react-burger-menu";
import Typed from "react-typed";
// import Particles from "react-particles-js";
// import forEach from "lodash/forEach";

import Vidoeplayer from "../components/video_player";
// import {isInViewport} from "../helpers/helper"

const { Header } = Layout;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      isOpen: false,
      selectedKeys: ["1"],
      dimension: []
    };
    this.breakePoint = 992;
    this.header = null;
    this.videoChecker = null;
    this.menuRefs = {}
  }
  componentDidMount() {
    window.addEventListener("resize", this.windowSizeSetting);
    this.windowSizeSetting();
    setTimeout(() => {
      this.header = document.querySelector(".kq-header");
    }, 10);
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.windowSizeSetting);
  }
  windowSizeSetting = () => {
    this.setState({ dimension: [window.innerWidth, window.innerHeight] });
  };
  render() {
    const {
      AppData: {
        menuItems,
        mediaContent: {
          home: { videos },
          // why_us: { images },
          common: { bg_black }
        }
      }
    } = this.props;
    const { dimension } = this.state;

    return (
      <Fragment>
        {dimension[0] < this.breakePoint && (
          <Fragment>
            <Menuburger right isOpen={this.state.isOpen}>
              {menuItems.map((item, index) => (
                <a
                key={index}
                  href={`#${item.toLowerCase().replace(/\s/g,'_')}`}
                  className={`${
                    index === this.state.selectedIndex ? "kq-menu-selected" : ""
                  }`}
                  onClick={() => {
                    this.setState({ selectedIndex: index });
                  }}
                  ref={ele=>this.menuRefs[item]=ele}
                >
                  <div>{item}</div>
                </a>
              ))}
            </Menuburger>
            <div className="progress-container">
              <div className="progress-bar" id="myBar"></div>
            </div>
          </Fragment>
        )}
        <Parallax
          bgImage={bg_black}
          strength={1000}
          className={"kq-studios-home"}
          renderLayer={percentage => {
            if (percentage > 0.9 && this.header) {
              this.header.classList.add("kq-header-viewed");
            } else if (this.header) {
              this.header.classList.remove("kq-header-viewed");
            }
            return <span />;
          }}
        >
          <BackTop
            onClick={() => {
              this.setState({ selectedIndex: 0 });
            }}
          />
          <div id="home" />
          {/* {dimension[0] > this.breakePoint &&<Particles
            className={"kq-particles"}
            params={{
              particles: {
                number: {
                  value: 100
                },
                color: {
                  value: "random"
                },
                size: {
                  value: 5
                },
                shape: {
                  type: ["polygon"]
                }
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: ["repulse", "grab"]
                  }
                }
              }
            }}
          />} */}
          <Vidoeplayer
            src={videos[0]}
            autoPlay
            loop
            background
          />

          {dimension[0] >= this.breakePoint && (
            <Header className={"kq-header"}>
              <div className="progress-container">
                <div className="progress-bar" id="myBar"></div>
              </div>
              <Menu
                theme="dark"
                mode="horizontal"
                forceSubMenuRender={true}
                defaultSelectedKeys={["1"]}
                selectedKeys={[...this.state.selectedKeys]}
                className={"kq-studios-menu"}
                // inlineCollapsed={false}
              >
                {menuItems.map((item, index) => (
                  <Menu.Item key={index + 1}>
                    <a
                      href={`#${item.toLowerCase().replace(/\s/g,'_')}`}
                      onClick={() => {
                        this.setState({ selectedKeys: [`${index + 1}`] });
                      }}
                      
                ref={ele=>this.menuRefs[item]=ele}
                    >
                      {item}
                    </a>
                  </Menu.Item>
                ))}
              </Menu>
            </Header>
          )}
          <Typed
            className={"kq-studios-typing"}
            strings={[
              '<span class="first-line">KnowledgeQ</span>\n<span class="second-line">3D and Viz Studio</span>'
            ]}
            typeSpeed={30}
            backSpeed={50}
            loop={false}
          />
        </Parallax>
      </Fragment>
    );
  }
}
