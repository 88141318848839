import React, { Component } from "react";


export default class Videoplayer extends Component {

  render() {  
    const { src, autoPlay, loop,background } = this.props;
    return (
      <div className={`${background?'vimeo-video-holder':'with-controllbar-holder'}`}>
      <iframe
        id="iframe-view"
        ref={el => this.iframe = el}
        title="video-player"
        src={`https://player.vimeo.com/video/${src}?background=${background?'1':'0'}&autoplay=${autoPlay?'1':'0'}&loop=${loop?'1':'0'}&muted=1`} 
        frameBorder="0"
        webkitallowfullscreen={true}
        mozallowfullscreen={true}
        allowFullScreen={true}
        className={`${background?'video-player-iframe':'with-controls'}`}>
      </iframe>
      </div>
    );
  }
}
