import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Row, Col } from "antd";

import Carouselslider from "../components/carousel_slider";

import { inViewport } from "../helpers/helper";

export default class Whatwedo extends Component {
  constructor(props) {
    super(props);
    this.isAnimated = false;
  }
  render() {
    const {
      AppData: {
        mediaContent: {
          common:{bg_black},
          what_we_do: {  contents }
        },
        textContent: { what_we_do }
      }
    } = this.props;
    return (
      <Parallax
        bgImage={bg_black}
        strength={1000}
        className={"kq-studios-whatwedo"}
        renderLayer={percentage => {
          if (
            this.viewElement &&
            inViewport(this.viewElement) &&
            !this.isAnimated
          )
            this.isAnimated = true;
            
          return (
            <div
              className={`kq-whatwedo-content whatwe-demo ${
                this.isAnimated ? "kq-studios-whatwedo-view" : ""
              }`}
              ref={ele => (this.viewElement = ele)}
              id="what_we_do"
            >
              <div className={"kq-whatewedo-title"}>What We do?</div>
              <Row type="flex" justify="space-around" className={"kq-whatwedo-content-holder"}>
              {
                what_we_do.map((item,index)=><Col xl={{span:8}} xs={{span:24}} className={"kq-whatwedo-text"} key={index}>
                <div className={"kq-whatwedo-description"}>{what_we_do[index].label}</div>
              <Carouselslider srcData={contents[item.key].images} speed={1500+(Math.floor(Math.random() * 1000) + 1)} autoplaySpeed={2500} />
                </Col>
              )
              }
              </Row>
            </div>
          );
        }}
      />
    );
  }
}
