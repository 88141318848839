import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Divider } from "antd";

import { inViewport } from "../helpers/helper";

export default class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.isAnimated = false;
    this.state={isAnimated:false}
  }
  render() {
    const {
      AppData: {
        mediaContent: {
          about_us: { bg }
        },
        textContent: { about_us }
      }
    } = this.props;
    return (
      <Parallax
        bgImage={bg}
        strength={400}
        className={"kq-studios-aboutus"}
        renderLayer={percentage => {
          if (
            this.viewElement &&
            inViewport(this.viewElement) &&
            !this.state.isAnimated
          )
          this.setState({isAnimated:true})

          return (
          <span />  
          );
        }}
      >
      <div
              className={`kq-aboutus-content ${
                this.state.isAnimated ? "kq-studios-aboutus-view" : ""
              }`}
              ref={ele => (this.viewElement = ele)}
              id="about_us"
              // style={{height:"600px"}}
            >
              <div className={"kq-title-holder-aboutus"}>
                <Divider orientation="left">About Us</Divider>
              </div>
              <div className={"kq-text-holder"}>
                {about_us.map((item,index) => (
                  <p dangerouslySetInnerHTML={{ __html: item }} key={index}/>
                ))}
              </div>
            </div>
      {/* <div className={'kq-parallax-heigth'} style={{height:'500px'}}></div> */}
      </Parallax>
    );
  }
}
