import React, { Component } from "react";
import { Modal,Carousel } from 'antd';

import Videoplayer from "./video_player"

export default class Popup extends Component {
  render() {
    const { closePopup,type,srcList, classNames } = this.props;
    return (
        <Modal
        className={`kq-popup ${type} ${classNames}`}
          centered
          visible={true}
          onCancel={closePopup}
        >
        <Carousel autoplay={false} arrows={true}  swipeToSlide={true}  infinite={true}>
        {
          srcList.map(item=>{
            const isImage = /\.(gif|jpe?g|tiff|png)$/i.test(item);
            if (isImage) {
              return (
                <img
                  src={item}
                  alt={item}
                  key={item}
                />
              );
            } else {
              return (
                <Videoplayer
                  src={item}
                  controllbar
                  autoPlay
                  key={item}
                />
              );
            }
          }
          )
            
        }
        </Carousel>
        </Modal>
    );
  }
}

