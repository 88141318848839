import React, { Component, Fragment } from "react";
import { Carousel } from "antd";

import Popup from "./popup";
import Videoplayer from "./video_player";

import {arraymove} from "../helpers/helper"

export default class Carouselslider extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false, src: null };
  }
  closePopup=() =>{
    this.setState({ showPopup: false });
    document.body.classList.remove('no-scroll');
  }
  makeOrderArray=(srcData,currentItem) =>{
    const fromIndex = srcData.indexOf(currentItem);
    return arraymove(srcData,fromIndex,0);
  }
  render() {
    const { srcData,speed,disableClickable,autoplaySpeed } = this.props;
    const { src, showPopup, type,srcList } = this.state;
    return (
      <Fragment>
        <Carousel autoplay={true} arrows={true} speed={speed} autoplaySpeed={autoplaySpeed}>
          {srcData.map((item,index) => {
            const isImage = /\.(gif|jpe?g|tiff|png)$/i.test(item);
            if (isImage) {
              return (<img
                src={item}
                alt={item}
                key={index}
                onClick={(evt) => {
                  if(!disableClickable) {
                    this.setState({ showPopup: true, type: "img",srcList:this.makeOrderArray([...this.props.srcData],item) });
                  }
                }}
              />
                
              );
            } else {
              return (
                <Videoplayer
                  src={item}
                  onClickVideo={() => {
                    if(!disableClickable)
                    this.setState({
                      showPopup: true,
                      type: "video",
                      srcList:this.makeOrderArray(this.props.srcData,item)
                    });
                  }}
                />
              );
            }
          })}
        </Carousel>
        {showPopup && (
          <Popup src={src} closePopup={this.closePopup} type={type} srcList={srcList} />
        )}
      </Fragment>
    );
  }
}
Carouselslider.defaultProps ={
  speed:1000,
  autoplaySpeed:2000
}